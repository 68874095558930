@tailwind utilities;

.content [contenteditable] {
  @apply h-full w-full outline-none;
}

.content a {
  @apply cursor-pointer underline underline-offset-4 hover:text-secondary;
}

.content [contenteditable] h1 {
  @apply mb-4 font-melodrama text-3xl font-medium;
}

.content [contenteditable] h2 {
  @apply mb-4 font-melodrama text-2xl font-medium;
}

.content [contenteditable] p {
  @apply mb-2;
}

.content [contenteditable] b {
  @apply font-medium;
}

.content [contenteditable] hr {
  @apply mx-4 my-4 h-auto border-secondary p-0;
}

.content [contenteditable] ol {
  @apply ml-8;
  list-style: decimal;
  list-style-position: outside;
}

.content [contenteditable] ul {
  @apply ml-8;
  list-style: unset;
  list-style-position: outside;
}

.content [contenteditable] ol li::marker {
  @apply inline-block w-6 tabular-nums;
  @apply !text-left;
}

.content p.is-editor-empty:first-child::before {
  @apply pointer-events-none float-left text-primary/60;
  content: attr(data-placeholder);
}
