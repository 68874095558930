.loader,
.loader:before,
.loader:after {
  background: currentColor;
  animation: load1 1s infinite ease-in-out;
  width: 0.5em;
}

.loader {
  color: inherit;
  text-indent: -9999em;
  position: relative;
  font-size: 1em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 2em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 3em;
  }
}
