@tailwind base;

@layer base {
  *::-webkit-scrollbar-track {
    box-shadow: transparent;
    -webkit-box-shadow: transparent;
    background-color: transparent;
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: theme('colors.secondary');
  }
}
